import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Gallery = () => {
  return (
    <Layout>
      <SEO title="Gallery" />
    </Layout>
  )
}

export default Gallery
